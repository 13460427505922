@import "src/assets/colors";

.group-wrapper {
  .group-container {
    margin-top: 20px;
    .group-header {
      font-size: 20px;
      font-weight: bold;
      font-family: PoppinsBold !important;
      display: flex;
      justify-content: space-between;
    }
    .group-body {
      margin-top: 30px;
      .id {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
      }
      .property {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .key {
          font-family: PoppinsBold !important;
          .mini-alert {
            padding: 5px 10px;
            border-radius: 4px;
            display: inline-block;
            margin: 5px 5px 0 0;
            font-weight: bold;
            text-align: center;
            font-size: 13px;
            .MuiAlert-message {
              padding: 0;
            }
          }
        }
        .value {
          color: $color-grey-dark;
          .voi-icon {
            margin-left: 5px;
          }

        }
      }
      .group-tabs {
        margin-top: 50px;
      }
    }
  }
}
