@import "src/assets/colors";

.app-call-txn-local-state-delta-wrapper {
  .app-call-txn-local-state-delta-container {
    border-radius: 10px;
    border: 1px solid $color-grey-light;
    margin-top: 30px;
    .props {
      margin-top: 0px !important;
    }
    .app-call-txn-local-state-delta-body {
      margin-top: 25px;
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
      .local-state-account-wrapper {
        .local-state-account-container {
          margin-bottom: 15px;
          .address {
            margin-bottom: 15px;
            font-size: 13px;
          }
        }
      }
    }
  }
}