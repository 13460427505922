@import 'src/assets/colors';

.settings-wrapper {
  .settings-container {
    .settings-header {
      border-radius: 10px;
      padding: 15px;
      .node {
        margin-right: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .settings-body {
      margin-top: 25px;
    }
  }
}