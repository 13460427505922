@import "src/assets/colors";

.asset-wrapper {
  .asset-container {
    margin-top: 20px;
    .asset-header {
      font-size: 20px;
      font-weight: bold;
      font-family: PoppinsBold !important;
      display: flex;
      justify-content: space-between;
    }
    .asset-body {
      margin-top: 30px;
      .index {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
      }

      .props {
        margin-top: 25px;
        border-radius: 10px;
        padding: 20px;
        .property {
          margin-top: 10px;
          .key {
            font-family: PoppinsBold !important;
          }
          .value {
            margin-top: 10px;
            color: $color-grey-dark;
            &.addr {
              font-size: 13px;
              word-break: break-word;
            }
          }
        }
      }

      .asset-tabs {
        margin-top: 25px;
      }
    }
  }
}
