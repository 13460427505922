@import "src/assets/colors";

.home-wrapper {
  .home-container {
    margin-top: 100px;
    .home-body {
      margin-top: 100px;
      .logo {
        width: 60px;
        height: 60px;
      }
      .tag-line {
        font-size: 24px;
      }
      .search-section {
        margin-top: 15px;
      }
      .live-section {
        margin-top: 60px;
        //background: $color-grey-very-light;
        //border-radius: 10px;
        //padding: 30px;
      }
    }
  }
}