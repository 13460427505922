@import "src/assets/colors";

.application-global-state-wrapper {
  .application-global-state-container {
    margin-top: 40px;
    border-radius: 10px;
    border: 1px solid $color-grey-light;
    .props {
      margin-top: 0px !important;
    }
    .application-global-state-body {
      margin-top: 25px;
      .copy-content {
        font-size: 16px;
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }
  }
}