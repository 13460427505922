@import "src/assets/colors";

.arc-wrapper {
  .arc-container {
    .arc-header {
      display: flex;
      justify-content: space-between;
      .arc-name {
        font-family: PoppinsBold !important;
        font-size: 25px;
        margin-top: 10px;
      }
    }
    .arc-tabs {
      margin-top: 20px;
    }
  }
}