@import "src/assets/colors";

.dispenser-wrapper {
  .dispenser-container {
    width: 400px;
    margin: 150px auto 0px;
    box-shadow: rgb(204 204 204 / 25%) 0 0 6px 3px;
    border-radius: 10px;
    padding: 50px;
    .dispenser-header {
      margin-bottom: 20px;
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid $color-grey-light;
      display: flex;
      justify-content: space-between;
    }
    .dispenser-body {
      
    }
  }
}