@import 'src/assets/colors';

.kmd-configuration-wrapper {
  .kmd-configuration-container {
    .kmd-configuration-header {
      margin-top: 10px;
      .node {
        margin-right: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .kmd-configuration-body {
      margin-top: 25px;
    }
  }
}