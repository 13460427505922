@import "src/assets/colors";

.abi-method-wrapper {
  .abi-method-container {
    .abi-method {
      margin-top: 15px;
      .method-exec {
        margin-right: 25px;
        float: right;
      }
      .method-name {
        font-size: 18px;
        font-weight: bold;
        margin-top: 5px;
        display: inline-block;
        margin-bottom: 5px;
      }
      .method-desc {
        margin-left: 15px;
        font-size: 13px;
        color: $color-grey-dark;
      }
      .method-body {
        margin-bottom: 15px;
        .method-title {
          font-weight: bold;
          margin: 15px 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid $color-grey-light;
          font-family: PoppinsBold !important;
          font-size: 14px;
        }
        .method-signature {
          .method-sig-section {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $color-light;
            .method-sig-section-key {
              margin-bottom: 5px;
              font-family: PoppinsLight !important;
              font-size: 14px;
              &.inline {
                display: inline-block;
              }
            }
            .method-sig-section-value {
              font-weight: bold;
              &.inline {
                display: inline-block;
                margin-left: 10px;
              }
            }
          }
        }
        .method-returns {
          margin-top: 0;
          padding-top: 15px;
          .method-returns-title {
            font-weight: bold;
            margin: 15px 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $color-grey-light;
            font-family: PoppinsBold !important;
            font-size: 14px;
          }
          .mini-alert {
            padding: 5px 10px;
            border-radius: 4px;
            display: inline-block;
            margin: 5px 5px 0 10px;
            font-weight: bold;
            text-align: center;
            font-size: 13px;
            .MuiAlert-message {
              padding: 0;
            }
          }
          .method-returns-desc {
            margin-left: 10px;
            font-size: 14px;
            color: $color-grey-dark;
            display: inline-block;
          }
        }
        .method-tabs {
          border-bottom: 1px solid $color-grey-light;
          margin-bottom: 20px;
        }

        .arguments {
          .arguments-title {
            font-weight: bold;
            margin: 20px 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $color-grey-light;
            font-family: PoppinsBold !important;
            font-size: 14px;
          }
          .arguments-header {
            padding: 15px;
            background: $color-grey-very-light;
            font-weight: bold;
            border-bottom: 1px solid $color-grey-light;
            border-top: 1px solid $color-grey-light;
          }
          .arg {
            padding: 15px;
            border-bottom: 1px solid $color-grey-light;
            .arg-prop {
              font-size: 14px;
            }
            &:hover {
              background: $color-grey-very-light;
            }
          }
        }
      }
    }
  }
}