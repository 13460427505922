@import "src/assets/colors";

.app-call-txn-foreign-assets-wrapper {
  .app-call-txn-foreign-assets-container {
    margin-top: 20px;
    .key {
      margin-bottom: 20px;
    }
    .item {
      margin: 10px 0px;
    }
  }
}