@import '../../assets/colors';

.qr-code-wrapper {
  .qr-code-container {
    text-align: center;
    margin-bottom: 44px;
  }
}

.left-bar-wrapper {
  .left-bar-container {
    height: 100vh;
    position: relative;
    border-right: 1px solid $color-grey-light;
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;

      .node {
        color: $color-dark;
        .node-url {
          text-align: center;
          font-size: 13px;
          color: $color-dark;
          @media only screen and (min-width: 901px) {
            svg {
              margin-right: 5px;
            }
          }
        }
        @media only screen and (max-width: 900px) {
          .label {
            display: none;
          }
          .node .node-url svg {
            margin-right: 0;
          }
        }
      }

      .bottom-menu-item-wrapper {
        .bottom-menu-item-container {
          text-align: center;
          padding: 15px 0;
          border-top: 1px solid $color-grey-light;
          .small-text {
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0 15px;
            font-weight: bold;
          }
          &:hover {
            cursor: pointer;
          }
          @media only screen and (max-width: 900px) {
            button {
              min-width: 0;
            }
            .label {
              display: none;
            }
          }
          @media only screen and (min-width: 901px) {
            .small-label {
              display: none;
            }
          }
        }
      }

    }
    .logo {
      height: 80px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      img {
        width: 18px;
        margin-right: 5px;
        vertical-align: top;
      }
      .text {
        display: inline-block;
        margin-top: 25px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .menu-list {
      margin: 50px auto 0;
      width: 80%;
      @media only screen and (max-width: 901px) {
        margin: 25px auto 0;
        .label {
          display: none;
        }
        button.MuiTab-root {
          min-width: 0;
          border-radius: 0;
          .MuiTab-iconWrapper {
            margin-right: 0;
          }
        }
      }
    }
  }
}
