@import 'src/assets/colors';

.setup-config-wrapper {
  .setup-config-container {
    .setup-config-header {
      margin-bottom: 15px;
      padding-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid $color-grey-light;
    }
  }
}