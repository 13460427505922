@import "src/assets/colors";

.import-abi-json-wrapper {
  .import-abi-json-container {
    .import-abi-json-header {
      margin-top: -15px;
      .tabs-wrapper {
        border-bottom: 1px solid $color-grey-light;
      }
    }
    .import-abi-json-body {
      margin-top: 20px;
      height: 400px;
      margin-bottom: 10px;
      .file-wrapper {
        height: inherit;
        .file-container {
          height: inherit;
          border: 1px dashed $color-grey;
          border-radius: 10px;
          background: $color-grey-very-light;
          margin-top: 40px;
          .upload-container {
            text-align: center;
            margin-top: 150px;
          }
        }
      }
      .url-wrapper {
        height: inherit;
        .url-container {
          height: inherit;
          margin-top: 40px;
        }
      }
    }
  }
}