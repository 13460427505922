@import "src/assets/colors";

.asset-arc-validator-content {
  .arc {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    .name {
      font-size: 20px;
      svg {
        vertical-align: sub;
      }
      .number {
        margin-left: 10px;
        font-family: PoppinsBold !important;
      }
    }
    .more {
      margin-top: -5px;
    }
  }
}

.asset-arc-validator-wrapper {
  .asset-arc-validator-container {

  }
}