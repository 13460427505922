@import "src/assets/colors";

.block-wrapper {
  .block-container {
    margin-top: 20px;
    .block-header {
      font-size: 20px;
      font-weight: bold;
      font-family: PoppinsBold !important;
      display: flex;
      justify-content: space-between;
    }
    .block-body {
      margin-top: 30px;
      .address {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
      }
      .props {
        margin-top: 25px;
        border-radius: 10px;
        padding: 0;
        .property {
          margin-top: 10px;
          .key {
            font-family: PoppinsBold !important;
          }
          .value {
            margin-top: 10px;
            color: $color-grey-dark;
            &.small {
              font-size: 13px;
            }
            .mini-alert {
              padding: 5px 10px;
              border-radius: 4px;
              display: inline-block;
              margin: 5px 5px 0 0;
              font-weight: bold;
              text-align: center;
              font-size: 13px;
              .MuiAlert-message {
                padding: 0;
              }
            }
          }
        }
      }
      .block-tabs {
        margin-top: 50px;
      }
    }
  }
}