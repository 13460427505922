@import "src/assets/colors";

.abi-networks-wrapper {
  .abi-networks-container {
    margin-top: 15px;
    .networks {
      padding-bottom: 25px;
      border-bottom: 1px solid $color-grey;
      .abi-networks-header {
        margin-bottom: 15px;
        svg {
          vertical-align: text-bottom;
          margin-right: 5px;
        }
      }
      .abi-networks-body {
        background: $color-light;
        border-radius: 10px;
        padding: 15px;
        .network-table-header {
          padding: 15px;
          font-family: PoppinsBold !important;
          font-weight: bold;
        }
        .network {
          display: flex;
          font-size: 14px;
          font-weight: bold;
          padding: 15px;
          .network-name {

          }
          .app-id {
            margin-left: 15px;
            color: $color-grey-dark;
            .app-link {
              vertical-align: top;
              margin-top: -7px;
              margin-left: 10px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}