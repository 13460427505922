@import "src/assets/colors";

.arc21-workspace-wrapper {
  .arc21-workspace-container {
    .arc21-workspace-header {
    }
    .arc21-workspace-body {
      margin-top: 10px;
    }
  }
}