@import '../../../../../assets/colors';

.asset-picker-wrapper {
  .asset-picker-container {
    height: 500px;
    .searching {
      height: 400px;
      overflow: auto;
      text-align: center;
      .text {
        margin-top: 20px;
      }
    }
    .no-results {
      height: 250px;
      text-align: center;
      padding-top: 150px;
    }
    .searched-assets {
      max-height: 400px;
      overflow: auto;
      .asset {
        padding: 10px;
        border-top: 1px solid $color-grey-light;
        font-size: 13px;
        position: relative;
        &:hover {
          background: $color-grey-light;
          cursor: pointer;
        }
        svg {
          position: absolute;
          right: 15px;
          top: 30px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}