@import "src/assets/colors";

.abi-studio-wrapper {
  .abi-studio-container {
    .abi-studio-header {
      margin-bottom: 15px;
      padding-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid $color-grey-light;
    }
    .abi-studio-body {
      .abi-intro {
        margin-top: 25px;
        height: 400px;
      }
    }
  }
}