@import "src/assets/colors";

.node-status-wrapper {
  .node-status-container {
    margin-top: 20px;
    .node-status-header {
      margin-bottom: 20px;
      padding-bottom: 20px;
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid $color-grey-light;
      display: flex;
      justify-content: space-between;
    }
    .node-status-body {
      
    }
  }
}