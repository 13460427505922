@import "src/assets/colors";

.create-app-content {
    padding-top: 20px;
    .teal-program {
      font-size: 10px;
      margin-top: 10px;
      word-break: break-all;
      background: $color-grey-very-light;
      padding: 15px;
      border-radius: 10px;
      height: 30px;
      overflow: auto;
    }
}

.create-app-wrapper {
  .create-app-container {

  }
}