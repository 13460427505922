@import "src/assets/colors";

.abi-editor-wrapper {
  .abi-editor-container {
    .abi-editor-body {
      margin-top: 15px;
      .abi {
        padding: 20px;
        border-radius: 10px;
        .abi-header {
          padding-bottom: 25px;
          border-bottom: 1px solid $color-grey;
          display: flex;
          justify-content: space-between;
          .abi-name {
            font-size: 20px;
            font-family: PoppinsBold !important;
            margin-bottom: 10px;
            color: #000 !important;
          }
          .abi-desc {
            font-size: 14px;
            color: $color-grey-dark;
          }
        }
        .abi-body {
          margin-top: 20px;
        }
      }
    }
  }
}