@import "src/assets/colors";

.account-wrapper {
  .account-container {
    margin-top: 20px;
    .account-header {
      font-size: 20px;
      font-weight: bold;
      font-family: PoppinsBold !important;
      display: flex;
      justify-content: space-between;
    }
    .account-body {
      margin-top: 30px;
      .address {
        font-size: 18px;
        margin-bottom: 20px;
        font-weight: normal;
        word-break: break-word;
      }
      .property {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        align-items: center;
        .key {
          font-family: PoppinsBold !important;
        }
        .value {
          color: $color-grey-dark;
          .voi-icon {
            margin-left: 5px;
          }
        }
      }
      .account-tabs {
        margin-top: 50px;
      }
    }
  }
}
