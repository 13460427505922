.app-root {
  .app-container {
    display: flex;
    justify-content: start;
    @media only screen and (max-width: 900px) {
      .app-left {
        width: 50px;
      }
    }
    @media only screen and (min-width: 901px) {
      .app-left {
        width: 250px;
      }
    }
    .app-right {
      width: 100%;
      .content-wrapper {
        height: 100vh;
        overflow: auto;
        @media only screen and (max-width: 900px) {
          .content-container {
            margin: 0px 0px 0px 10px;
          }
        }
        @media only screen and (min-width: 901px) {
          .content-container {
            margin: 20px 100px 100px 100px;
          }
        }
      }
    }
  }

}
